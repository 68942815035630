import React, {useEffect, useState} from 'react'
import { Row, Col, Container, Breadcrumb, Dropdown } from "react-bootstrap"
import { Link } from "@StarberryUtils";
import { useLocation } from "@reach/router"
import DropdownImg from '../../../images/downArrow.svg'

const LocalLoveFilter = (props) => {
    const[url, setUrl] = useState("")
    const location = useLocation();
  
    useEffect(() => {
        props.list.map(({node}) => {
         if(location.pathname.includes(node.URL)){
            setUrl(node.URL)
         } 
        })
    }, [url])
    
            
    // console.log(url, "bkibjkbjkbj")
  return (
    <div className='filter_box'>
    <Container>
        <div className="btns-wrp d-md-flex align-items-center cta_drop_down_item_center_new">
            {props.area_drop_down ? (
                <div className="area_drop_down cta_drop_down">
                <Dropdown>
                    <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    >
                    Explore All Areas
                    <img src={DropdownImg} alt="dropdown"/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='dropdown_menu'>
                    <Dropdown.Item>
                        <Link to={`/about/loving-life/restaurant-and-bars`} >
                        All Areas
                        </Link>
                    </Dropdown.Item>
                    {props.area_drop_down.map((data, i) => {
                        return (
                        <Dropdown.Item className={(props?.area_drop_down_value == data.strapiId ? 'active' : '')}>
                            <Link to={`/about/loving-life/${url}/${data.URL}`}>
                            {data.Title}
                            </Link>
                        </Dropdown.Item>
                        )
                    })}
                    </Dropdown.Menu>
                </Dropdown>
                </div>
            ) : (
                ""
            )}

            <div
                className={
                    props.showcta == "yes"
                        ? "banner-local-love-list"
                        : "banner-local-love-list"
                    }
            >
            {props.list.map(({ node }, i) => {
            if (!location.pathname.includes(node.URL)) {
                // console.log(location.pathname, node.URL);
                return (
                <Link to={`/about/loving-life/${node.URL}`} className="btn">
                    {node.Name}
                </Link>
                )
            }
            })}
        </div>
        </div>
    </Container>
    </div>
  )
}

export default LocalLoveFilter