import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby'
import {Col, Row, Container } from 'react-bootstrap'
import HeaderOne from "../components/header/header"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelpStyleThree"
import ReactMarkdown from "react-markdown"
import Filter from '../components/Static/StaticBanner/LocalLoveFilter'
import $ from "jquery";
import { Link } from "@StarberryUtils";

import StaticBanner from "../components/Static/StaticBanner/area-guide-static-banner-ggfx"
import MGFav from '../components/Static/MgFavourites/MgFavouritesList'

const LocalLifeMainLanding = (props) => {

    const data = props.data.strapiLocalLifeCategories
    const cuisines = props.data.allStrapiRestaurantCuisines.edges
	const list = props.data.allStrapiLocalLifeCategories.edges

  	const [ eat_in, setEatIn ] = useState('');
  	const [ takeaways, setTakeaways ] = useState('');
  	const [ cuisine, setCuisine ] = useState('');
  	const [ filter_res, setFilterRes ] = useState(data.local_life_things_to_dos);

	var area_list = [], filter_resturant = [] , filter_resturant_cuisine = [];
	for(var i=0; i < props.data.allStrapiAreaGuides.edges.length; i++){
		area_list.push(props.data.allStrapiAreaGuides.edges[i].node)
	}

	let areas_list = area_list.filter( (ele, ind) => ind === area_list.findIndex( elem => elem.strapiId === ele.strapiId))

	function filterTeam(event,data) {
 		const eat = $(".eat:checked").val()
 		const takeaways = $(".takeaways:checked").val()
 		const cusines = $("#cuisines").val()
 		setEatIn(eat);
 		setTakeaways(takeaways);
 		setCuisine(cusines);
	}


	useEffect(() => {

		if(eat_in === "eat-in" || takeaways === "takeaways"){
		filter_resturant = [];

			data.local_life_things_to_dos.map((data,index) => {
				if((eat_in === "eat-in" && data.EatIn === true) && (takeaways === undefined)) {

			        filter_resturant.push(data);

			    } else if((takeaways === "takeaways" && data.Takeaways === true) && (eat_in === undefined)) {

			        filter_resturant.push(data);

			    }
			    else if((eat_in === "eat-in" && takeaways === "takeaways") && (data.EatIn === true && data.Takeaways === true)) {

			        filter_resturant.push(data);

			    }
			});

			if(cuisine !== "all") {
			filter_resturant_cuisine = [];
			filter_resturant.map((data,index) => {
				if(data.Cuisine == cuisine) {
					filter_resturant_cuisine.push(data);
				}
			});
			if(filter_resturant_cuisine.length == 0) {
				setFilterRes('');
			}
				filter_resturant_cuisine && filter_resturant_cuisine.length > 0 && setFilterRes(filter_resturant_cuisine);
			}

			else {
				filter_resturant && filter_resturant.length > 0 && setFilterRes(filter_resturant);
			}

		} else {
			if(cuisine == "all" || cuisine == '') {
			setFilterRes(data.local_life_things_to_dos);
			}
			else {

			data.local_life_things_to_dos.map((data,index) => {
			if(data.Cuisine == cuisine) {
					filter_resturant_cuisine.push(data);
				}
			});

			if(filter_resturant_cuisine.length == 0) {
				setFilterRes('');
			}
			filter_resturant_cuisine && filter_resturant_cuisine.length > 0 && setFilterRes(filter_resturant_cuisine);
			}
		}



	},[eat_in,takeaways,cuisine])


    let filter = "hide"

    if(data.Alias == 'restaurant-and-bars') {
    	filter = "show"
    }
    else if(data.Alias == 'shops') {
    	filter = "hide"

    }

	return (
		<div className="local-life-area-landing-page restaurant-landing">
	        <HeaderOne />

			{data.Banner_Image !== null &&
                <>
                <StaticBanner Title={data.Banner_Content} CTA_1_URL="test" CTA_2_URL="#" Banner_Image={data.Banner_Image} tag="local-life" area_drop_down={areas_list} main_url="main" id={data.strapiId} data={data} img="local-life-categories.Banner_Image.details"/>
                </>
			}

            <Container className="intro">
			{/* <Link to="/about/loving-life/" className="back-to text-uppercase"> &lt; Back to Local Loves</Link> */}
            	<Row className="content">
                	<Col xs={12} sm={12} lg={8}>
                		<div className="content-one">
                			<ReactMarkdown source={data.Intro_Content} allowDangerousHtml />
                		</div>
                	</Col>
                	<Col xs={1}>
                	</Col>
                	<Col xs={12} sm={12} lg={3} className="east-schedule">
                		{filter == 'show' ?
                		<div className="filter">
                			<div className="cuisines">
                				<select name="cuisines" id="cuisines" className="cuisines" onChange={filterTeam}>
                					<option value="all">All Cuisines</option>
				                    {cuisines.map(({node}, i) => {
				                      return (
                						<option value={node.strapiId}>{node.Name}</option>
                					  )
                					})}
                				</select>
                			</div>
                			<div className="checkbox">
                				<Row className="east-check-box">
                					<Col xs={6} className="east-filter-checkbox">
                						<p><input type="checkbox" value="eat-in" className="eat" id="eat" name="Eat in" onClick={filterTeam} data-filter="eat_in"/><label for="eat">Eat in</label></p>
                					</Col>
                					<Col xs={6} className="east-filter-checkbox">
                						<p><input type="checkbox" value="takeaways" className="takeaways" id="takeaways" onClick={filterTeam} data-filter="takeaways"/><label for="takeaways">Takeaways</label></p>
                					</Col>
                				</Row>
                			</div>
                		</div> : '' }
                	</Col>
            	</Row>
            </Container>
            {filter_res.length == 0 ?
            <div className="no-result">
            	<p>Sorry, Not available for your selection</p>
            </div> :
            <MGFav data={filter_res} url={data.URL}/> }

			<Filter area_drop_down={areas_list}  list={list} />
			
	        <CanHelp />

	        <Footer Select_Popular_Search="Static_Contact" popularSearch="static"/>
		</div>
	)
}

export default LocalLifeMainLanding



export const pageQuery = graphql`query LocalLifeAreaMainLandingQuery($slug: String!) {
  strapiLocalLifeCategories(strapiId: {eq: $slug}) {
    Banner_Content
    Intro_Content
    Meta_Description
    Meta_Title
    Name
    URL
    Alias
    strapiId
    Banner_Image {
      internal {
        description
      }
    }
    local_life_things_to_dos {
      Name
      Address
	  Offer_MSG
      Short_Description
      Tile_Image_Label
      URL
	  Featured
      EatIn
      Takeaways
      Cuisine
      Tile_Image {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 400, height: 210, layout: CONSTRAINED)
        }
      }
    }
    local_life_things_to_dos {
      area_guide
    }
    imagetransforms {
      Banner_Image_Transforms
    }
  }
  allStrapiAreaGuides(
    filter: {Local_life_things_to_dos: {elemMatch: {Category: {eq: $slug}}}}
  ) {
    edges {
      node {
        Title
        URL
        strapiId
      }
    }
  }
  allStrapiLocalLifeCategories(filter: {local_life_things_to_dos: {elemMatch: {Featured: {eq: true}}}, Publish: {eq: true}}, sort: {fields: Sort, order: ASC}) {
    edges {
      node {
        Sort
        Name
        URL
        Alias
        strapiId
        local_life_things_to_dos {
          area_guide
        }
      }
    }
  }
  allStrapiRestaurantCuisines(filter: {Publish: {eq: true}}) {
    edges {
      node {
        Name
        Alias
        strapiId
      }
    }
  }
}
`
