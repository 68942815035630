import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button, Nav, Tabs, Tab } from 'react-bootstrap';
import VideoCard from '../VideoCard/VideoCard';
import "./MgFavourites.scss";
import favImg01 from "../../../images/fav-img01.jpg"
import favImg02 from "../../../images/fav-img02.jpg"
import favImg03 from "../../../images/fav-img03.jpg"
import favImg04 from "../../../images/fav-img04.jpg"
import { navigate, useLocation } from "@reach/router"
import MgLocalLoveLogo from '../../../images/mg_logo_local.svg'
import LikeCommentBlock from '../../LocalLoveLikeCommentBlock'
import ShowMoreText from 'react-show-more-text';
import ReactMarkdown from "react-markdown"

const MgFavouritesList = (props) => {
 

  const data = useStaticQuery(graphql`{
    allStrapiLocalLifeComments(
      filter: {status: {eq: "Approved"}}
      limit: 99999
      sort: {fields: createdAt, order: DESC}
    ) {
      edges {
        node {
          name
          comments
          local_life_things_to_do {
            id
            URL
          }
        }
      }
    } 
}
`);

  const commites = data.allStrapiLocalLifeComments.edges

  const [key, setKey] = useState('restaurant-and-bars');
  const count = props.data.length
  var type = "odd"
  if (count % 2 == 0) {
    type = "even";
  }


  return (
    <section className="mg-favourites mg-fav-landing">
      <Container className="tab-content">
        <Row className="mg-things-do-list">
          {props.data.length > 0 && props.data.map((data, i) => {
            let eat_in = ''
            if (data.Eat_in == true) {
              eat_in = "eat_in"
            }
            let takeaways = ''
            if (data.Takeaways == true) {
              takeaways = "takeaways"
            }
            return (
              <Col xl={4} className={`mg-list ${eat_in} ${takeaways}`}>
                  <div className={`favourite-item no-border`}>
                    <figure>
                      {data.Tile_Image ? <Link to={`/about/loving-life/${props.url}/${data.URL}`} className="link"><img loading="lazy" src={data.Tile_Image.childImageSharp && data.Tile_Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${data.Name} - Martyn Gerrard`} /> </Link> : ''}
                      {data.Tile_Image_Label ? <span className="tag-abs text-uppercase">{data.Tile_Image_Label}</span> : ''}
                    </figure>


                    {data.Featured && <img src={MgLocalLoveLogo} className="mg_logo_local_love" alt="logo" />}
                    <div className="info">
                      <h4 className="info-title">{data.Name}</h4>
                      <span className="add-txt">{data?.Address?.substring(0, 50)} {data?.Address?.length > 50 ? '...' : ''}</span>
                      <LikeCommentBlock commites={commites} url={data.URL} name={data.Name} />
                      {/* <ReactMarkdown source={data.Short_Description} allowDangerousHtml className="desc" /> */}
                      <ShowMoreText
                        lines={4}
                        more='More'
                        less='Less'
                        className='desc-content'
                        anchorClass='btn-more review-more'
                        expanded={false}
                        keepNewLines={false}
                      >

                        {data.Short_Description}
                      </ShowMoreText>
                      <div className="business_offer_btn">
                        {
                          data.Offer_MSG &&   <Button onClick={() => { navigate('/about/coupon-code/') }} className="btn btn-pink" type="submit">
                            {data.Offer_MSG}
                          </Button>
                        }
                      </div>
                    </div>
                  </div>
              </Col>

            );
          })}
        </Row>

      </Container>
    </section>
  );
}

export default MgFavouritesList
