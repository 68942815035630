import { Link } from "@StarberryUtils";
// import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import {Col, Row, Container } from 'react-bootstrap';
// import ReactMarkdown from "react-markdown"
// import HelpCard from "./HelpCard/HelpCard"
// import BgShadowWhite from "../../../images/bg-shadow-white2.svg";
import  "../CanHelp/CanHelp.scss";

import { LOCAL_RECOMMENDATION_PAGE_URL } from "../../common/site/constants";

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.3
    }
  }
}

const RecommendationCanHelp=()=> {

    return(
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="help-sec components--Home--CanHelp--CanHelp"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <Container>
              <Row className="recommendation"> 
                <Col md={8} className="col1">
                  <h2 className="sec-title text-center text-md-left">Do you have a <span className="d-inline">local business, club or event</span> that you’d like to recommend?</h2>
                </Col>
                <Col md={4} className="col2">
                  <Link to={`${LOCAL_RECOMMENDATION_PAGE_URL.alias}`} className="btn btn-success">make a recommendation</Link>
                </Col>
              </Row>
            </Container>
          </motion.section>
        )}
      </InView>
    )
  }
export default RecommendationCanHelp
